
import Hero from '@/components/Hero'
import Standfirst from '@/components/Standfirst'
import utils from '@/mixins/utils'

export default {
  mixins: [utils],

  components: {
    Hero,
    Standfirst,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
