
import Media from '@/components/Media'
import Animation from '@/components/Animation'
import Btn from '@/components/Btn'
import Price from '@/components/shop/Price'
import BuyButton from '@/components/shop/BuyButton'
export default {
  components: {
    Media,
    Animation,
    Btn,
    BuyButton,
    Price,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
    carousel: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    batch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    product() {
      return this.$store.getters['shop/getProduct'](this.data.sku)
    },
  },
}
