import { render, staticRenderFns } from "./RelatedEvent.vue?vue&type=template&id=3edee900&scoped=true&"
import script from "./RelatedEvent.vue?vue&type=script&lang=js&"
export * from "./RelatedEvent.vue?vue&type=script&lang=js&"
import style0 from "./RelatedEvent.vue?vue&type=style&index=0&id=3edee900&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edee900",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Media: require('/vercel/path0/components/Media.vue').default,Animation: require('/vercel/path0/components/Animation.vue').default})
