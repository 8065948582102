
import utils from '@/mixins/utils'
import RelatedEvent from '@/components/teasers/RelatedEvent'

export default {
  mixins: [utils],

  components: {
    RelatedEvent,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  computed: {
    nextEvent() {
      const current = this.events.findIndex(
        (event) => event.id === this.data.id
      )
      return this.events[current - 1] || this.events[this.events.length - 1]
    },
    prevEvent() {
      const current = this.events.findIndex(
        (event) => event.id === this.data.id
      )
      return this.events[current + 1] || this.events[0]
    },
    events() {
      let events = this.$store.state.events
      events = events.filter(
        (event) =>
          event.attributes.event_category.data.id ===
          this.data.event_category.data.id
      )
      return events
    },
  },
}
