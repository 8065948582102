
import utils from '@/mixins/utils'
import Animation from '@/components/Animation'
import Media from '@/components/Media'
export default {
  mixins: [utils],
  components: {
    Animation,
    Media,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
    align: {
      type: String,
      default: 'left',
    },
  },
}
