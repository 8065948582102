
import utils from '@/mixins/utils'
import ModuleArticles from '@/components/modules/ModuleArticles'

export default {
  mixins: [utils],

  components: {
    ModuleArticles,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
