
import Media from '@/components/Media'
import Animation from '@/components/Animation'
import Btn from '@/components/Btn'
export default {
  components: {
    Media,
    Animation,
    Btn,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  computed: {
    types() {
      let str = ''
      this.data.article_categories.data.forEach((category, index) => {
        str += this.$store.state.articleCategories.find(
          (c) => c.id === category.id
        ).attributes.title

        if (index < this.data.article_categories.data.length - 1) {
          str += ', '
        }
      })
      return str
    },
  },
}
