
export default {
  watch: {
    '$store.state.shop.products'() {
      this.initProduct()
    },
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  mounted() {
    if (this.product) {
      this.initProduct()
    }
  },
  methods: {
    initProduct() {
      const container = document.createElement('div')
      this.$refs.product.appendChild(container)
      window.ui.createComponent('product', {
        id: this.product.id.split('/').pop(),
        node: container,
        moneyFormat:
          this.$store.state.shop.buybutton.currency +
          ' {{amount_no_decimals_with_comma_separator}}',
        options: {
          product: {
            iframe: false,
            buttonDestination: 'cart',
            contents: {
              img: false,
              title: false,
              options: false,
              price: false,
            },
            templates: {
              button:
                '<span class="{{data.classes.product.price}}">{{data.formattedPrice}} <span class="{{data.classes.product.compareAt}}">{{data.formattedCompareAtPrice}}</span></span>',
            },
          },
        },
      })
    },
  },
}
