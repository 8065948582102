
import utils from '@/mixins/utils'
import StrapiImage from '@/components/StrapiImage'
import Animation from '@/components/Animation'
import Standfirst from '@/components/Standfirst'
import Price from '@/components/shop/Price'
import BuyButton from '@/components/shop/BuyButton'
import Geolocation from '@/components/Geolocation'
export default {
  mixins: [utils],

  components: {
    StrapiImage,
    Animation,
    Standfirst,
    Price,
    BuyButton,
    Geolocation,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  head() {
    return {
      script: [{ type: 'application/ld+json', json: this.structuredData }],
    }
  },
  computed: {
    product() {
      return this.$store.getters['shop/getProduct'](this.data.sku)
    },
    structuredData() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: this.data.title,
        description: this.data.description || this.data.info,
        offers: this.data.cost
          ? {
              price: this.data.cost,
              priceCurrency: 'DKK',
            }
          : null,
        image: [this.data.hero.data.attributes.url],
      }
    },
  },
}
