import gql from "graphql-tag";
import { modules } from '~/graphql/subqueries/modules'
import { seo } from '~/graphql/subqueries/seo'

export const recipeQuery = gql`
  query Recipe($publicationState: PublicationState!, $slug: String!) {
    recipes(filters:{slug: {eq: $slug}}, publicationState: $publicationState) {
      data {
      id
        attributes {
          title
          info
          description
          products {
            data {
              id
            }
          }
          recipe_type {
            data {
              id
            }
          }
          recipe_method {
            data {
              id
            }
          }
          steps {
            ... on ComponentSharedStep {
              label
              time
            }
          }
          ingredients {
            ... on ComponentSharedIngredients {
              text
            }
          }
          gear {
            ... on ComponentSharedGear {
              text
            }
          }
          preptime
          servings
          media {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          video {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
                provider_metadata
                createdAt
                updatedAt
              	size
              }
            }
          }
          ${modules}
          ${seo}
        }
      }
    }
  }
`