
import utils from '@/mixins/utils'
import ModuleProducts from '@/components/modules/ModuleProducts'
import ModuleRecipes from '@/components/modules/ModuleRecipes'

export default {
  mixins: [utils],

  components: {
    ModuleProducts,
    ModuleRecipes,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
