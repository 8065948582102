
import utils from '@/mixins/utils'
import VideoPlayer from '@/components/VideoPlayer'
import Standfirst from '@/components/Standfirst'
import Hero from '@/components/Hero'

export default {
  mixins: [utils],
  components: {
    VideoPlayer,
    Standfirst,
    Hero,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      current: 0,
    }
  },
  head() {
    return {
      script: [{ type: 'application/ld+json', json: this.structuredData }],
    }
  },
  computed: {
    recipeMethod() {
      return this.$store.state.recipeMethods.filter((method) => {
        return this.data.recipe_method.data
          ? parseInt(this.data.recipe_method.data.id) === parseInt(method.id)
          : null
      })
    },
    recipeTypes() {
      return this.$store.state.recipeTypes.filter((type) => {
        return this.data.recipe_type.data.some((f) => {
          return parseInt(f.id) === parseInt(type.id)
        })
      })
    },
    counts() {
      return this.data.steps.map((a) => a.time)
    },
    structuredData() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Recipe',
        name: this.data.displaytitle || this.data.title,
        description: this.data.description || this.data.info,
        prepTime: 'PT' + this.data.preptime + 'M' || 'PT2M',
        recipeCategory: 'Drink',
        recipeInstructions: this.data.steps.map((step) => {
          return { '@type': 'HowToStep', text: step.label }
        }),
        recipeIngredient: this.data.ingredients.map((ingredient) => {
          return ingredient.text
        }),
        image: [this.data.media.data[0].attributes.url],
        video: {
          '@type': 'VideoObject',
          name: this.data.displaytitle || this.data.title,
          description: this.data.description || this.data.info,
          contentUrl: this.data.video.data.attributes.url,
          thumbnailUrl: this.data.media.data[0].attributes.url,
          uploadDate: this.data.video.data.attributes.createdAt,
        },
      }
    },
  },
  methods: {
    timeupdate(event) {
      const time = event.detail.plyr.currentTime

      const found = [...this.counts].reverse().find((e) => e <= time)
      this.current = this.counts.findIndex((e) => e === found)
    },
    goto(time) {
      this.$refs.video.goto(time)
    },
  },
}
