
import utils from '@/mixins/utils'
import Standfirst from '@/components/Standfirst'
import Btn from '@/components/Btn'

export default {
  mixins: [utils],

  components: {
    Standfirst,
    Btn,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  head() {
    return {
      script: [{ type: 'application/ld+json', json: this.structuredData }],
    }
  },

  computed: {
    previous() {
      const today = new Date()
      const date = new Date(this.data.date)
      return date < today
    },
    structuredData() {
      return {
        '@context': 'http://schema.org',
        '@type': 'Event',
        name: this.data.title,
        description: this.data.description || this.data.info,
        startDate: this.data.date,
        eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
        location: {
          '@type': 'Place',
          name: 'Copenhagen Distillery',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Kløvermarksvej 70D',
            addressLocality: 'København S',
            postalCode: '2300',
            addressCountry: 'DK',
          },
        },
        image: [this.data.media.data[0].attributes.url],
      }
    },
  },
}
