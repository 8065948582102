
import ArticleTeaser from '@/components/teasers/ArticleTeaser'
import Filters from '@/components/Filters'
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  components: {
    ArticleTeaser,
    Filters,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    parentId: {
      type: Number,
      default: 0,
    },
    manual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backdoor: 0,
      filters: [
        {
          name: 'type',
          field: 'article_categories',
          data: this.createOptions(this.$store.state.articleCategories),
        },
      ],
    }
  },
  mounted() {
    this.backdoor++
  },
  computed: {
    filteredArticles() {
      let articles = this.articles
      if (this.$route.query && this.backdoor && this.$refs.filter) {
        const query = Object.keys(this.$route.query)
        const filters = this.$refs.filter.filters

        query.forEach((name) => {
          const filter = filters.find((filter) => filter.name === name)

          const id = filter.data.find(
            (f) => f.code === this.$route.query[name]
          ).id

          articles = articles.filter((article) => {
            let found = false
            if (Array.isArray(article.attributes[filter.field].data)) {
              found = article.attributes[filter.field].data.find(
                (item) => parseInt(item.id) === parseInt(id)
              )
            } else {
              found =
                parseInt(article.attributes[filter.field].data.id) ===
                parseInt(id)
            }
            return found
          })
        })
      }

      const limit = this.data.limit || 3
      if (limit !== -1) {
        articles = articles.slice(0, limit)
      }

      return articles
    },
    articles() {
      return this.data.articles && this.data.articles.data.length > 0
        ? this.$store.state.articles.filter((article) => {
            return this.data.articles.data.some((f) => {
              return f.id === article.id
            })
          })
        : !this.manual
        ? this.$store.state.articles.filter(
            (article) => Number(article.id) !== this.parentId
          )
        : []
    },
  },
}
