import { render, staticRenderFns } from "./ProductTop.vue?vue&type=template&id=734d8cf8&scoped=true&"
import script from "./ProductTop.vue?vue&type=script&lang=js&"
export * from "./ProductTop.vue?vue&type=script&lang=js&"
import style0 from "./ProductTop.vue?vue&type=style&index=0&id=734d8cf8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734d8cf8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StrapiImage: require('/vercel/path0/components/StrapiImage.vue').default,Animation: require('/vercel/path0/components/Animation.vue').default,Geolocation: require('/vercel/path0/components/Geolocation.vue').default,Standfirst: require('/vercel/path0/components/Standfirst.vue').default})
