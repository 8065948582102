
export default {
  computed: {
    country() {
      return this.$store.state.shop.country
    },
    validCountry() {
      return this.$store.state.config.buybutton.find(
        (buybutton) => buybutton.country === this.country
      )
    },
  },
}
