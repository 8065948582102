import gql from "graphql-tag";
import { modules } from '~/graphql/subqueries/modules'
import { seo } from '~/graphql/subqueries/seo'

export const productQuery = gql`
    query Product($publicationState: PublicationState!, $slug: String!) {
    products(filters:{slug: {eq: $slug}}, publicationState: $publicationState) {
      data {
        id
        attributes {
          title
          displaytitle
          batch
          available
          sku
          cost
          shop
          recipes {
            data {
              id
            }
          }
          packshot {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          hero {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          product_category {
            data {
              id
              attributes {
                title
              }
            }
          }
          description
          info
          ${modules}
          ${seo}
        }
      }
    }
  }
`