import gql from "graphql-tag";
import { modules } from '~/graphql/subqueries/modules'
import { seo } from '~/graphql/subqueries/seo'

export const eventQuery = gql`
    query Event($publicationState: PublicationState!, $slug: String!) {
    events(filters:{slug: {eq: $slug}}, publicationState: $publicationState) {
      data {
      id
        attributes {
          title
          date
          tickets
          info
          description
          media {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          event_category {
            data {
              id
              attributes {
                title
              }
            }
          }
          ${modules}
          ${seo}
        }
      }
    }
  }
`