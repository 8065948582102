
import { Navigation, Mousewheel, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import ProductTeaser from '@/components/teasers/ProductTeaser'
import utils from '@/mixins/utils'
import 'swiper/modules/navigation.min.css'
import 'swiper/modules/mousewheel.min.css'
import 'swiper/modules/scrollbar.min.css'
export default {
  mixins: [utils],
  components: {
    ProductTeaser,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    manual: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    batch: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        observer: true,
        observeParents: true,
        grabCursor: true,
        // freeMode: true,
        slidesPerView: 1.3,
        modules: [Navigation, Mousewheel, Scrollbar],
        spaceBetween: 20,
        scrollbar: {
          el: '#swiper-scrollbar-' + this._uid,
          draggable: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
        breakpoints: {
          768: {
            slidesPerView: this.data.large ? 1.3 : 2.5,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: this.data.large ? 1.7 : 3,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: this.data.large ? 2.2 : 3.5,
            spaceBetween: 30,
          },
        },
        navigation: {
          nextEl: '#swiper-button-next-' + this._uid,
          prevEl: '#swiper-button-prev-' + this._uid,
        },
      },
    }
  },
  setup() {
    return {
      modules: [Navigation, Mousewheel],
    }
  },
  computed: {
    filteredProducts() {
      if (
        this.data.product_categories &&
        this.data.product_categories.data.length > 0 &&
        Number(this.data.product_categories.data[0].id) === 1
      ) {
        // If whisky - only show the most recent batch
        const products = []
        this.$store.state.whisky.forEach((whisky) => {
          if (whisky[0].attributes.batch)
            products.push(this.sortOnBatch([...whisky])[0])
        })
        return products
      } else {
        return this.data.product_categories &&
          this.data.product_categories.data.length > 0
          ? this.$store.state.products.filter((product) => {
              return this.data.product_categories.data.some((f) => {
                if (product.attributes.product_category.data) {
                  return (
                    Number(f.id) ===
                    Number(product.attributes.product_category.data.id)
                  )
                } else {
                  return false
                }
              })
            })
          : this.$store.state.products
      }
    },

    products() {
      let products =
        this.data.products && this.data.products.data.length > 0
          ? this.filteredProducts.filter((product) => {
              return this.data.products.data.some((f) => {
                return f.id === product.id
              })
            })
          : !this.manual
          ? this.filteredProducts.filter(
              (product) => Number(product.id) !== this.parentId
            )
          : []
      const limit = this.data.limit || -1
      if (limit !== -1) {
        products = products.slice(0, limit)
      }
      return products
    },
  },
}
